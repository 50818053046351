/**
 * For Point Page/Area
 */


.p-point {
  padding: 100px 0;
  background: #f0f5f1;
  
  @include mq-sp {
    padding: 50px 0;
  }
}

.p-point_list {
  
}

.p-point_list--item {
  position: relative;
  margin-bottom: 125px;
  
  @include mq-sp {
    margin-bottom: 20px;
  }
  
  &:last-of-type {
    margin-bottom: 65px;
    
    @include mq-sp {
      margin-bottom: 0;
    }
  }
}

.p-point_kv {
  position: relative;
  width: 87.5%;
  height: 640px;
  overflow: hidden;
  
  @include mq-sp {
    width: 100%;
    height: auto;
  }
  
  &.p-point_kv--reverse {
    margin-left: auto;
  }
}

.p-point_kv_image {
  @include mq-sp {
    height: auto;
  }
}

.p-point_kv_text {
  font-size: 1.4rem;
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  
  @include mq-sp {
    top: 10px;
    bottom: auto;
  }
}

[data-aos="pointKv"] {
  opacity: 0;
  transform: translate3d(0,10px,0);
  transition-property: transform, opacity;
  
  &.aos-animate {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}

.p-point_kv_image img {
  max-width: none;
  height: 100%;
  
  @include mq-sp {
    max-width: 100%;
    height: auto;
  }
}

.p-point_content {
  width: 580px;
  padding: 30px;
  position: absolute;
  background: #fff;
  bottom: -65px;
  right: 0;
  overflow: hidden;
  
  @include mq-sp {
    width: 90%;
    padding: 15px;
    position: relative;
    top: -20px;
    left: 0;
    right: 0;
    margin: auto;
  }
  
  &.p-point_content--reverse {
    left: 0;
  }
}

.p-point_content_box {
  display: flex;
  
  @include mq-sp {
    display: block;
  }
}

.p-point_content_box_thumb {
  max-width: 150px;
  margin-right: 30px;
  
  @include mq-sp {
    display: flex;
    justify-content: center;
    max-width: none;
    margin-right: 0;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  & a {
    display: block;
    transition: 0.5s all ease-in;
    
    @include mq-sp {
      display: inline-block;
    }
    
    &:hover {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
      transition: 0.5s all ease-in;
    }
  }
  
  & img {
    @include mq-sp {
      max-height: 160px;
    }
    
  }
}

[data-aos="pointContent"] {
  opacity: 0;
  transform: translate3d(0,10px,0);
  transition-property: transform, opacity;
  
  &.aos-animate {
    transform: translate3d(0,0,0);
    opacity: 1;
  }
}

.p-point_content_title {
  @extend .c-heading-2;
  font-size: 3.0rem;
  color: $color-green;
  &:after {
    background: $color-green;
  }
  
  @include mq-sp {
    font-size: 2.4rem;
  }
}

.p-point_content_text {
  font-size: 1.6rem;
  line-height: 1.875;
}