/**
 * For Privacy Page/Area
 */

.p-privacy {
  padding: 0 0 140px;
  
  @include mq-sp {
    padding: 0 15px 50px;
  }
}

.p-privacy_heading {
  margin-bottom: 60px;
  
  @include mq-sp {
    margin-bottom: 30px;
  }
}

.p-privacy_box {
  width: 1000px;
  margin: auto;
  padding: 50px 100px 30px;
  border: 1px solid $color-green;
  
  @include mq-sp {
    width: 100%;
    padding: 30px;
  }
}

.p-privacy_box_text {
  font-size: 1.6rem;
  line-height: 1.875;
  margin-bottom: 50px;
  @include mq-sp {
    margin-bottom: 30px;
  }
}