@charset "utf-8";

html {
  height: 100%;
  font-size: 10px;
}
body {
  height: 100%;
  color: $text-base-color;
  font-family: $font-serif;
  line-height: (32/16);
}
a {
  text-decoration: none;
}
img {
  max-width: 100%;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    &[src$=".svg"],
    &[src$=".svgz"] {
      width: 100%;
    }
  }
}