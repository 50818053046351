@charset "utf-8";

/*-- フォーム / form --------------------*/

.c-form-1 {
}

.c-form-1_item {
  margin-bottom: 20px;
}

.c-form-1_title {
  font-family: $font-serif-heading;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 8px;
}

.c-form-1_title--require {
  display: inline-block;
  padding: 3px 10px;
  margin-left: 20px;
  background: $color-green;
  font-size: 1.4rem;
  line-height: 1;
  color: #fff;
}

.c-form-1_title--any {
  @extend .c-form-1_title--require;
  background: #707070;
}

.c-form-1_input {
  width: 100%;
  height: 42px;
  padding: 5px 9px;
  border: 1px solid #7d7d7d;
  font-family: $font-serif;
  font-size: 1.6rem;
  color: #000;
  position: relative;
  transition: 0.3s all ease;
  
  &:focus {
    outline: 0;
    border-bottom: 3px solid $color-green;
    transition: 0.3s all ease;
  }
}

.c-form-1_textarea {
  @extend .c-form-1_input;
  height: 250px;
}