/**
 * For Message Page/Area
 */


.p-cooking {
  padding: 80px 0;
  background: #e5dcd6;
  
  @include mq-sp {
    padding: 30px 15px;
  }
}

.p-cooking_heading {
  margin-bottom: 80px;
  
  @include mq-sp {
    margin-bottom: 40px;
  }
}

.p-cookeing_list {
  width: 1000px;
  margin: 0 auto;
  
  @include mq-sp {
    width: 100%;
  }
}

.p-cookeing_list--item {
  display: flex;
  background: #fff;
  margin-bottom: 70px;
  position: relative;
  
  @include mq-sp {
    display: block;
    margin-bottom: 50px;
  }
  
  &:last-of-type {
    margin-bottom: 0;
  }
}

.p-cookeing_list_photo {
  width: 50%;
  flex: none;
  
  @include mq-sp {
    width: 100%;
  }
}

.p-cookeing_list_content {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 50px;
  position: relative;
  z-index: 1;
  
  @include mq-sp {
    padding: 20px 30px;
  }
  
  &:after {
    content: '';
    display: block;
    width: 45px;
    height: 70px;
    position: absolute;
    right: 24px;
    top: 30px;
    z-index: 0;
    
    @include mq-sp {
      top: 20px;
      width: 20px;
      height: 30px;
    }
  }

  &.is_num01:after {
    background: url(../../assets/img/cooking/cooking_num01.png)no-repeat;
    background-size: contain;
  }

  &.is_num02:after {
    background: url(../../assets/img/cooking/cooking_num02.png)no-repeat;
    background-size: contain;
  }

  &.is_num03:after {
    background: url(../../assets/img/cooking/cooking_num03.png)no-repeat;
    background-size: contain;
  }
}

.p-cookeing_list_content_box {
  position: relative;
  z-index: 1;
}

.p-cookeing_list_title {
  font-size: 3.0rem;
  line-height: 1.2;
  margin-bottom: 18px;
  
  @include mq-sp {
    font-size: 2.4rem;
    margin-bottom: 12px;
  }
}

.p-cookeing_list_text {
  font-size: 1.6rem;
  line-height: 1.875;
  text-align: justify;
}

.p-cooking_linkbtnWrap {
  display: flex;
  justify-content: center;
  margin-top: 70px;
  
  @include mq-sp {
    margin-top: 30px;
  }
}

.p-cooking_linkbtn {
  @extend .c-btn-1;
  width: 480px;
  height: 60px;
}