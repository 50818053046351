/**
 * For Buy Page/Area
 */

.p-contact {
  padding: 80px 0 100px;
  background: #e5dcd6;
  
  @include mq-sp {
    padding: 30px 15px;
  }
  
  &.p-contact--thanks {
    height: 100%;
  }
}

.p-contact_heading {
  margin-bottom: 60px;
  
  @include mq-sp {
    margin-bottom: 40px;
  }
}

.p-contact_box {
  width: 1000px;
  margin: 0 auto 70px;
  background: #fff;
  padding: 50px 50px 60px;
  
  @include mq-sp {
    width: 100%;
    padding: 20px 20px 40px;
    margin-bottom: 40px;
  }
}

.p-contact_box_heading {
  font-family: $font-serif-heading;
  font-size: 2.4rem;
  font-weight: bold;
  color: $color-green;
  margin-bottom: 40px;
  text-align: center;
  
  @include mq-sp {
    font-size: 2.1rem;
    margin-bottom: 30px;
  }
}

.p-contact_fax_area {
  width: 430px;
  margin: auto;
  
  @include mq-sp {
    width: 100%;
  }
}

.p-contact_fax_area_title {
  font-family: $font-serif-heading;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 20px;
  
  @include mq-sp {
    margin-bottom: 15px;
  }
}

.p-contact_fax_area_text {
  font-size: 1.6rem;
  line-height: 1.5;
  text-align: justify;
  margin-bottom: 40px;
  
  @include mq-sp {
    margin-bottom: 20px;
  }
}

.p-contact_fax_area_info {
  display: flex;
  width: 100%;
  min-height: 60px;
  font-family: $font-serif-heading;
}

.p-contact_fax_area_info--title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;
  width: 150px;
  font-size: 2.4rem;
  font-weight: bold;
  color: #fff;
  background: $color-green;
  
  @include mq-sp {
    width: auto;
    padding: 0 20px;
  }
}

.p-contact_fax_area_info--detail {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 2.4rem;
  font-weight: bold;
  color: #000;
  background: #d2eadb;
}

.p-contact_fax_area_info--tel {
  font-family: $font-serif-heading;
  text-align: center;
  margin-top: 15px;
  font-size: 18px;
  color: #444;
  
  @include mq-sp {
    color: #666;
  }
}

.p-contact_form {
  width: 540px;
  margin: auto;
  
  @include mq-sp {
    width: 100%;
  }
}

.p-contact_form_attention {
  font-size: 1.6rem;
}

.p-contact_form_auth {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.p-contact_form_btnWrap {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.p-contact_form_btn {
  @extend .c-btn-1;
  width: 380px;
  transition: 0.3s all ease-in;
  
  &:disabled {
    background: #ccc;
    transition: 0.3s all ease-in;
  }
}

.p-contact_thanks {
  text-align: center;
}

.p-contact_thanks_text {
  font-size: 1.6rem;
  line-height: 1.5;
  margin-bottom: 2em;
  
  &:last-of-type {
    margin-bottom: 0;
  }
}

.p-contact_err_box {
  margin-top: 40px;
}

.p-contact_err_text p {
  font-family: $font-sans-serif;
  font-size: 1.6rem;
  line-height: 1.5;
  margin-bottom: 10px;
  color: #ff0000;
}