@charset "utf-8";

/*-- 見出し / heading --------------------*/

.c-heading-1 {
  font-family: $font-serif-heading;
  font-weight: bold;
  font-size: 3.6rem;
  line-height: 1;
  text-align: center;
  
  @include mq-sp {
    font-size: 2.4rem;
    line-height: 1.2;
  }
  
  &:after {
    content: '';
    display: block;
    width: 70px;
    height: 2px;
    margin: 30px auto 0;
    background: #000;
    
    @include mq-sp {
      margin-top: 20px;
    }
  }
}

.c-heading-2 {
  font-family: $font-serif-heading;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 1;
  margin-bottom: 30px;
  
  @include mq-sp {
    margin-bottom: 15px;
  }
  
  &:after {
    content: '';
    display: block;
    width: 50px;
    height: 1px;
    margin: 20px 0 0;
    background: #000;
    
    @include mq-sp {
      margin-top: 15px;
    }
  }
}

