@charset "utf-8";

/*-- ボタン / btn --------------------*/

.c-btn-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 270px;
  height: 60px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #fff;
  background: $color-green;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  
  &:hover {
    background: darken($color-green,15%);
    transition: 0.3s all ease-in-out;
  }
}

