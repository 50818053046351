/**
 * For Top Page
 */

.p-top_main {
  position: relative;
  height: 720px;
  overflow: hidden;
  
  @include mq-sp {
    height: 180px;
  }
}

.p-top_main_box {
  position: absolute;
  top: 160px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  
  @include mq-sp {
    padding: 20px; 
    top: 0;
  }
}

.p-top_main_box_heading {
  font-family: $font-sans-serif;
  font-weight: bold;
  font-size: 5.4rem;
  line-height: 1;
  margin-bottom: 50px;
  position: relative;
  text-shadow: 0 0 3px #fff, 0 1px 7px #fff;
  
  &:after {
    content: '';
    display: block;
    width: 800px;
    height: 1px;
    background: #000;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -25px;
    
    @include mq-sp {
      width: 80%;
      bottom: -10px;
    }
  }
  
  @include mq-sp {
    font-size: 2.4rem;
    margin-bottom: 20px;
  }
}

.p-top_main_box_heading--inner {
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  font-size: inherit;
  @include mq-sp {
//    background: rgba(255, 255, 255, 0.9);
  }
}

.p-top_main_box_catchcopy {
  font-size: 3.2rem;
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 35px;
  text-shadow: 0 0 3px #fff, 0 1px 7px #fff;
  
  @include mq-sp {
    font-size: 1.4rem;
    margin-bottom: 5px;
  }
}

.p-top_main_box_text {
  font-size: 2.4rem;
  line-height: 1.5;
  font-weight: bold;
  
  @include mq-sp {
    font-size: 1.1rem;
//    background: rgba(255, 255, 255, 0.9);
  }
}

.p-top_main_box_text--inner {
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  font-size: inherit;
  text-shadow: 0 0 3px #fff, 0 1px 7px #fff;
}

.p-top_mv {
}

.p-top_mv--item {
  overflow: hidden;
  
  &.slick-current img {
//    animation-name: topMvZoom;
//    animation-duration: 7s;
//    animation-timing-function: ease;
//    animation-iteration-count: 1;
//    animation-fill-mode:forwards;
  }
}

@keyframes topMvZoom {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.1);
    }
}