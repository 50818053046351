@charset "utf-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-style:normal;
	font-weight: normal;
	font-size: 100%;
  line-height: 1;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

html{
    overflow-y: scroll;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

div {
    display: block;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

input, textarea {
	margin: 0;
	padding: 0;
}

ol, ul{
    margin: 0;
    padding: 0;
    list-style:none;
}

table{
    border-collapse: collapse; 
    border-spacing:0;
}

caption, th{
    text-align: left;
}

a{
    text-decoration: none;
}

a:focus {
	outline:none;
}

img {
    vertical-align: middle;
}

figure img {
    max-width: 100%;
}