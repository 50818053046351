@charset "utf-8";

dfn, cite, em, i {
	font-style: italic;
}

blockquote {
	margin: 0 1.5em;
}

pre {
	background: #eee;
	font-family: "Courier 10 Pitch", Courier, monospace;
	font-size: 15px;
	font-size: 0.9375rem;
	line-height: 1.6;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}

code, kbd, tt, var {
	font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
	font-size: 15px;
	font-size: 0.9375rem;
}

abbr, acronym {
	border-bottom: 1px dotted #666;
	cursor: help;
}

mark, ins {
	background: #fff9c0;
	text-decoration: none;
}

big {
	font-size: 125%;
}

@font-face {
  font-family: 'iconatom';
  src:
    url('../fonts/iconatom.ttf?7w31up') format('truetype'),
    url('../fonts/iconatom.woff?7w31up') format('woff'),
    url('../fonts/iconatom.svg?7w31up#iconpaw') format('svg');
  font-weight: normal;
  font-style: normal;
}