@charset "utf-8";
/**
 * Layout Style
 */

.l-container {
  width: $container--pc;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 1220px) {
    width: $container--sp;
    max-width: $container--pc;
  }

  @media screen and (max-width: 1200px) {
    width: $container--sp;
  }
}

.l-body {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.l-content {
  flex: 1 0 auto;
}

.l-main {
  display: block;
}

//ヘッダー
.l-header {
}

.l-header_container {
  width: $container--pc;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @media screen and (max-width: 1220px) {
    width: $container--sp;
    max-width: $container--pc;
  }
  @media screen and (max-width: 1200px) {
    width: $container--sp;
    height: 87.5px;
  }
}

//フッター
.l-footer {
  padding: 25px 0;
  border-top: 1px solid #535353;
  text-align: center;
}

.l-footer_title {
  font-family: $font-serif-heading;
  font-size: 1.8rem;
  font-weight: bold;
}

.l-footer_small {
  display: block;
  font-size: 1.4rem;
  color: #666;
  margin-top: 16px;
}
