@charset "utf-8";

/*-- スライド / カルーセル / slide --------------------*/

@include mq-sp {
  .slick-prev {
    left: 10px !important;
  }
  .slick-next {
    right: 10px !important;
  }
}

.slick-prev:before, .slick-next:before {
  color: #000 !important;
}

