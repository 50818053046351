/**
 * For Produce Page/Area
 */


.p-produce {
  padding: 100px 0;
  background: #e5dcd6;
  @include mq-sp {
    padding: 0;
  }
}

.p-produce_box {
  display: flex;
  position: relative;
}

.p-produce_photo {
  width: 65%;
  
  @include mq-sp {
    width: 100%;
  }
  
  & img {
    max-width: 100%;
  }
}

.p-produce_content {
  width: 39.1%;
  position: absolute;
  right: 0;
  bottom: 50px;
  
  @include mq-sp {
    width: 90%;
    margin: 0 auto;
    right: 0;
    left: 0;
    bottom: 15px;
  }
}

.p-produce_content_title {
  display: inline-block;
  font-family: $font-serif-heading;
  font-weight: bold;
  background: #fff;
  padding: 10px;
  font-size: 3.0rem;
  margin-bottom: 20px;
  
  @include mq-sp {
    font-size: 1.8rem;
    margin-bottom: 10px;
    padding: 5px;
    background: rgba(255, 255, 255, 0.8);
  }
}

.p-produce_content_text {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 90px;
  padding: 10px;
  font-size: 1.6rem;
  line-height: 1.875;
  background: #fff;
  
  @include mq-sp {
    text-align: justify;
    min-height: 0;
    padding: 5px;
    background: rgba(255, 255, 255, 0.8);
  }
}