/**
 * For Info Page/Area
 */

.p-info {
  padding: 80px 0 100px;
  
  @include mq-sp {
    padding: 30px 15px 50px;
  }
}

.p-info_heading {
  margin-bottom: 60px;
  
  @include mq-sp {
    margin-bottom: 30px;
  }
}

.p-info_box {
  display: flex;
  justify-content: space-between;
  
  @include mq-sp {
    display: block;
  }
}

.p-info_box_content {
  width: 370px;
  
  @include mq-sp {
    width: 100%;
    margin-bottom: 40px;
  }
}

.p-info_box_title {
  font-family: $font-serif-heading;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 18px;
}

.p-info_box_text {
  font-size: 1.6rem;
  line-height: 1.5;
}