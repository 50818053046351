/**
 * For Message Page/Area
 */


.p-message {
}

.p-message_intro {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 730px;
  background: url(../../assets/img/message/bg_message_image.jpg)no-repeat;
  background-size: cover;
  
  @include mq-sp {
    min-height: 0;
    padding: 30px 0;
  }
}

.p-message_intro_box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1000px;
  padding: 65px 0;
  background: rgba(0, 86, 31, 0.8);
  
  @include mq-sp {
    width: 80%;
    padding: 20px;
  }
  
  & p {
    font-size: 2.0rem;
    line-height: 1.5;
    text-align: center;
    color: #fff;
    
    @include mq-sp {
      font-size: 1.3rem;
    }
  }
}