@charset "utf-8";
// color
$key-color: #000000;
$text-base-color: #000000;
$color-red: #742d2d;
$color-green: #00561f;

// font
$font-sans-serif: "Hiragino Kaku Gothic ProN","Hiragino Kaku Gothic Pro",メイリオ,Meiryo,sans-serif;
$font-serif: "Yu Mincho", "YuMincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
$font-serif-heading: 'Noto Serif JP', "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;

// margin
$margin--xs: 10px;
$margin--sm: 14px;
$margin--md: 30px;
$margin--lg: 50px;
$margin--xl: 70px;

// size
$font-size--xs: 14px;
$font-size--sm: 18px;
$font-size--md: 21px;
$font-size--lg: 24px;
$font-size--title: 30px;

// container
$container--pc: 1200px;
$container--sp: 100%;