/**
 * For Product Page/Area
 */

.p-product {
  padding: 80px 0 100px;
  
  @include mq-sp {
    padding: 50px 0;
  }
}

.p-product_heading {
  margin-bottom: 70px;
  
  @include mq-sp {
    margin-bottom: 30px;
  }
}

.p-product_item_list {
  display: flex;
  justify-content: space-between;
  justify-content: space-around;
  padding: 0 100px;
  margin-top: 110px;
  
  @include mq-sp {
    display: block;
    padding: 0 15px;
    margin-top: 50px;
  }
}

.p-product_item_list--item {
  width: 320px;
  flex: none;
  
  @include mq-sp {
    width: 100%;
    margin-bottom: 50px;
    
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.p-product_item_photo {
  margin-bottom: 15px !important;
}

.p-product_item_dots {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  
  & li {
    width: 30px;
    height: 30px;
  }
  
  & button {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    font-family: $font-serif;
    font-size: 1.6rem;
    color: #fff;
    background: #7aa489;
    border: none;
    outline: 0;
  }
  
  & .slick-active button {
    background: $color-green;
  }
}

.p-product_item_content {
  
}

.p-product_item_title {
  font-family: $font-serif-heading;
  font-size: 3.0rem;
  font-weight: bold;
  text-align: center;
  color: $color-red;
  margin-bottom: 25px;
}

.p-product_item_text {
  font-size: 1.6rem;
  line-height: 1.5;
}

.p-product_item_price {
  margin-top: 16px;
}

.p-product_item_price_list {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 10px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.p-product_item_price_list_title {
  font-size: 1.6rem;
  text-align: right;
  margin-right: 20px;
}

.p-product_item_price_list_price {
  font-size: 1.6rem;
  text-align: right;
}

.p-product_item_price_list_price--emp {
  font-size: 1.5em;
  line-height: 1;
}

.p-product_item_price_list_price--postage {
  font-size: 1.4rem;
  text-align: right;
  margin-top: 5px;
}


.p-product_feature {
  padding: 100px 0;
  
  @include mq-sp {
    padding: 30px 0;
  }
}

.p-product_feature_box {
  display: flex;
  padding: 0 100px;
  
  @include mq-sp {
    display: block;
    padding: 0 15px;
  }
  
  &.isList {
    display: flex !important;
  
    @include mq-sp {
      display: block !important;
      padding-left: 40px;
      padding-right: 40px;
    }
    
    &:focus {
      outline: none;
    }
  }
}

.p-product_feature_photo {
  flex: none;
  width: 218px;
  margin-right: 90px;
  
  @include mq-sp {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
}

.p-product_feature_title {
  font-family: $font-serif-heading;
  font-size: 3.6rem;
  font-weight: bold;
  color: $color-red;
  margin-bottom: 42px;
  
  @include mq-sp {
    text-align: center;
    font-size: 3.2rem;
    margin-bottom: 24px;
  }
}

.p-product_feature_content_text {
  font-size: 1.6rem;
  line-height: 1.875;
  text-align: justify;
  
  @include mq-sp {
    line-height: 1.5;
  }
}

.p-product_feature_price {
  margin: 35px 0 0;
  
  @include mq-sp {
    margin-top: 20px;
  }
}

.p-product_feature_price_list {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.p-product_feature_price_list--title {
  font-size: 2.4rem;
  margin-right: 30px;
}

.p-product_feature_price_list--detail {
//  flex: none;
//  width: 200px;
  font-size: 1.6rem;
}

.p-product_feature_price_list--emp {
  font-size: 2.4rem;
}

.p-product_feature_btnWrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 60px;
  
  @include mq-sp {
    justify-content: center;
    margin-top: 30px;
  }
}