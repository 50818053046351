@charset "utf-8";

.u-bg--dark {
    background: #eeeeee;
}

/* margin */

.u-mt--none {
    margin-top: 0 !important;
}
.u-mb--none {
    margin-bottom: 0 !important;
}
.u-ml--none {
    margin-left: 0 !important;
}
.u-mr--none {
    margin-right: 0 !important;
}

.u-mt--xs {
    margin-top: $margin--xs;
}
.u-mb--xs {
    margin-bottom: $margin--xs;
}
.u-ml--xs {
    margin-left: $margin--xs;
}
.u-mr--xs {
    margin-right: $margin--xs;
}

.u-mt--sm {
    margin-top: $margin--sm;
}
.u-mb--sm {
    margin-bottom: $margin--sm;
}
.u-ml--sm {
    margin-left: $margin--sm;
}
.u-mr--sm {
    margin-right: $margin--sm;
}

.u-mt--md {
    margin-top: $margin--md;
}
.u-mb--md {
    margin-bottom: $margin--md;
}
.u-ml--md {
    margin-left: $margin--md;
}
.u-mr--md {
    margin-right: $margin--md;
}

.u-mt--lg {
    margin-top: $margin--lg;
}
.u-mb--lg {
    margin-bottom: $margin--lg;
}
.u-ml--lg {
    margin-left: $margin--lg;
}
.u-mr--lg {
    margin-right: $margin--lg;
}

.u-mt--xl {
    margin-top: $margin--xl;
}
.u-mb--xl {
    margin-bottom: $margin--xl;
}
.u-ml--xl {
    margin-left: $margin--xl;
}
.u-mr--xl {
    margin-right: $margin--xl;
}

/* padding */

.u-pt--none {
    padding-top: 0;
}
.u-pb--none {
    padding-bottom: 0;
}
.u-pl--none {
    padding-left: 0;
}
.u-pr--none {
    padding-right: 0;
}

.u-pt--xs {
    padding-top: $margin--xs;
}
.u-pb--xs {
    padding-bottom: $margin--xs;
}
.u-pl--xs {
    padding-left: $margin--xs;
}
.u-pr--xs {
    padding-right: $margin--xs;
}

.u-pt--sm {
    padding-top: $margin--sm;
}
.u-pb--sm {
    padding-bottom: $margin--sm;
}
.u-pl--sm {
    padding-left: $margin--sm;
}
.u-pr--sm {
    padding-right: $margin--sm;
}

.u-pt--md {
    padding-top: $margin--md;
}
.u-pb--md {
    padding-bottom: $margin--md;
}
.u-pl--md {
    padding-left: $margin--md;
}
.u-pr--md {
    padding-right: $margin--md;
}

.u-pt--lg {
    padding-top: $margin--lg;
}
.u-pb--lg {
    padding-bottom: $margin--lg;
}
.u-pl--lg {
    padding-left: $margin--lg;
}
.u-pr--lg {
    padding-right: $margin--lg;
}

.u-pt--xl {
    padding-top: $margin--lg;
}
.u-pb--xl {
    padding-bottom: $margin--xl;
}
.u-pl--xl {
    padding-left: $margin--xl;
}
.u-pr--xl {
    padding-right: $margin--xl;
}

/* font */

.u-txt--left {
    text-align: left !important;
}

.u-txt--center {
    text-align: center !important;
}

.u-txt--right {
    text-align: right !important;
}

.u-txt--justify {
    text-align: justify !important;
}

.u-txt--ib {
    display: inline-block;
}

.u-txt--super {
  vertical-align: super;
  font-size: 0.5em;
}

.u-font_size--xs {
    font-size: $font-size--xs;
}

.u-font_size--sm {
    font-size: $font-size--sm;
}

.u-font_size--md {
    font-size: $font-size--md;
}

.u-font_size--lg {
    font-size: $font-size--lg;
}


/*-- サイズ指定など ------------------*/

.u-width--12 {
    width: 100%;
}

.u-width--11 {
    width: 91.66666667%;
}

.u-width--10 {
    width: 83.33333333%;
}

.u-width--9 {
    width: 75%;
}

.u-width--8 {
    width: 66.66666667%;
}

.u-width--7 {
    width: 58.33333333%;
}

.u-width--6 {
    width: 50%;
}

.u-width--5 {
    width: 41.66666667%;
}

.u-width--4 {
    width: 33.33333333%;
}

.u-width--3 {
    width: 25%;
}

.u-width--2 {
    width: 16.66666667%;
}

.u-width--1 {
    width: 8.33333333%;
}

.u-clearfix {
    &:after {
        content: '';
        display: block;
        clear: both;
    }
}

.u-br--pc {
  display: none;
  @include mq-sp {
    display: block;
  }
}

.u-br--sp {
  @include mq-sp {
    display: none;
  }
}